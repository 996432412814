<template>
	<section class="section--padding corporate-orders">
		<v-container class="d-md-none pb-6 text-center">
			<h3>{{ gifts.NAME }}</h3>
		</v-container>
		<home-swiper v-if="!isWindowMD" :items="items" alt="For your special occasion" />
		<v-container class="container--narrow">
			<v-row justify="space-between">
				<v-col cols="12" md="6" lg="5" class="pr-md-8 pr-lg-0 pt-md-8">
					<h3 class="mb-8 d-none d-md-block">{{ gifts.NAME }}</h3>

					<div class="text-center text-md-left d-flex flex-column align-center align-md-start"
                    v-html="gifts.DETAIL_TEXT">

					</div>
				</v-col>
				<v-col cols="12" md="5" lg="6">
					<home-swiper v-if="isWindowMD" :items="items" alt="For your special occasion" />
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script>
    import HomeSwiper from "../../components/home-swiper";

    export default {
        name: "corporate-orders",
        components: {
            HomeSwiper,
        },
        data() {
            return {
                items: [
                    {img: require('@/assets/home/corporate-orders/S0.jpg'),},
                    {img: require('@/assets/home/corporate-orders/S1.jpg'),},
                   // {img: require('@/assets/home/corporate-orders/S2.jpg'),},
                   // {img: require('@/assets/home/corporate-orders/S3.jpg'),},
                ],
            }
        },
        computed: {
            isWindowMD() {
                return this.$store.getters.isWindowMD;
            },

            gifts() {
                return this.$store.state?.page?.HomeController?.gifts;
            }
        },
    }
</script>

<style lang="scss">
	.corporate-orders {
		//background: $primary-50;
	}

</style>