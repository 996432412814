<template>
	<div v-if="true" class="flavour-list">
		<template v-for="(row, rowIndex) in rows">
			<v-row justify="center" class="relative">
				<v-col v-for="(item, index) in row.items" :key="'item'+index"
					   :cols="item.cols || row.cols" :id="'col'+item.ID"
					   class="flavour-list__item" :class="{'no-transition':category.UF_WITHOUT_HOVER_TRANSITION}">

					<div class="relative">
						<div class="py-4 py-lg-6 d-flex flex-column justify-start align-center relative z-index-1"
							 :class="{open:!!selectedItem}">

							<div class="flavour-list__item-images" :style="imagesStyle(item)">

								<div class="flavour-list__item-img" :style="itemStyle(item)">
									<span class="hover" @click="onClick(item)" :class="{pointer:!item.isProduct}" :id="'hover'+item.ID"/>
									<v-img :src="item.PREVIEW_PICTURE"
										   :alt="item.NAME" contain
										   :class="{image:item.DETAIL_PICTURE,single:!item.DETAIL_PICTURE}"/>
									<v-img v-if="item.DETAIL_PICTURE" :src="item.DETAIL_PICTURE" :alt="item.name"
										   class="cut"/>
								</div>

								<div v-if="item.mini"
									 class="flavour-list__item-img-mini"
									 :style="itemStyleMini(item.mini)">
									<span class="pointer hover" @click="onClick(item.mini)" :id="'hover'+item.mini.ID"/>
									<v-img :src="item.mini.PREVIEW_PICTURE"
										   :alt="item.mini.NAME"
										   :class="{image:item.DETAIL_PICTURE}"/>
									<v-img v-if="item.DETAIL_PICTURE" :src="item.DETAIL_PICTURE" :alt="item.name"
										   class="cut"/>
								</div>
							</div>

							<div class="flavour-list__item-text" @click="onClick(item)" :class="{pointer:!item.isProduct}">
								<div class="h5 mt-4 mt-lg-7 text-center flavour-list__name"
									 v-html="nameHtml(item)"></div>
								<div class="size2 mt-1 text-center flavour-list__choco" v-html="subnameHtml(item)"></div>
							</div>
						</div>

						<v-img v-if="item.PREVIEW_UNDER"
							   :src="item.PREVIEW_UNDER"
							   :alt="item.name"
							   contain
							   class="under"/>
					</div>

				</v-col>
			</v-row>
			<transition name="drop-small">
				<v-row v-if="selectedItem && row.items.some(el=>(el.ID===selectedItem.ID||(el.mini&&el.mini.ID===selectedItem.ID)))" class="flavour-list__info">
					<v-col cols="12">

						<flavour-info :item="selectedItem"
									  :mini="selectedItem.mini"
									  :tri-left="selectedTriLeft(row)"
									  @buy="onBuy" @close="onClose"/>

					</v-col>
				</v-row>
			</transition>
		</template>
	</div>
	<div v-else class="swiper flavour-list" :id="'flavour-list'+idCounter">
		<div class="swiper-wrapper">
			<div class="swiper-slide" v-for="(item, index) in items" :key="index">

				<router-link :to="{name:'flavour', params:{code:item.CODE}}">
					<div class="px-2 d-flex flex-column justify-start align-center relative z-index-1">
						<div class="flavour-list__item-images">
							<div class="flavour-list__item-img">
								<v-img :src="item.PREVIEW_PICTURE" :alt="item.NAME"/>
							</div>
							<div v-if="item.mini" class="flavour-list__item-img-mini">
								<v-img :src="item.mini.PREVIEW_PICTURE"
									   :alt="item.mini.NAME"/>
							</div>
						</div>
						<!--<div class="size2 mt-4 text-center flavour-list__name" v-html="nameHtml(item)"></div>-->
						<div class="h6 mt-4 text-center flavour-list__name" v-html="nameHtml(item)"></div>
						<div class="size3 mt-1 text-center flavour-list__choco" v-html="subnameHtml(item)"></div>
						<!--<div class="size2 text-center flavour-list__choco">{{ item.PREVIEW_TEXT }}</div>-->
					</div>
				</router-link>
				<v-img v-if="item.PREVIEW_UNDER" :src="item.PREVIEW_UNDER" :alt="item.name" contain class="under"/>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import FlavourInfo from './flavour-info';
	import {scrollTo} from '@/utils/utils';
	import {Swiper} from 'swiper';
	import 'swiper/swiper.min.css';
	import vp from "@/mixins/vp";

	let idCounter = 1;

	export default {
		name: "flavour-list",
		mixins: [vp],
		components: {
			FlavourInfo,
		},
        props: ["items", "category", "products"],
		data() {
			return {
				idCounter: 0,
				selectedItem: null,
				isSelectedItemVisible: false
			}
		},
		computed: {
			productCategories() {
				return this.$store.state.common.sections;
			},
			itemWidth() {
				return this.category.UF_ITEM_WIDTH;
			},
			itemHeight() {
				return this.category.UF_ITEM_HEIGHT;
			},
			xsCols() {
				return this.category.UF_XS_COLS;
			},
			itemsProcessed() {
				if (!this.category.PICTURE) return this.items;
				return [{
					isProduct: true,
					PREVIEW_PICTURE: this.category.PICTURE,
					NAME: this.category.UF_LIST_NAME,
					ID: "0",
				}, ...this.items];
			},
			rows() {
				const rows = [];
				let items = [];
				let index = 0;
				let cols = null;

				const customCols = this.category.UF_COLS_JSON?.cols;
				if ( customCols ) {
					// кастомные колонки из админки

					// todo
					const colsPerRow = this.isXS ? 1 : 4;

					cols = null;
					this.itemsProcessed.forEach((el, i) => {
						if (customCols[i]) {
							let c = null;
							if (this.isLG && customCols[i].lg) c = customCols[i].lg;
							else if (this.isMD && customCols[i].md) c = customCols[i].md;
							else if (this.isSM && customCols[i].sm) c = customCols[i].sm;
							else if (this.isXS && customCols[i].xs) c = customCols[i].xs;
							if (c) Vue.set(el, "cols", c);
						}
						items.push(el);

						if (items.length >= colsPerRow) {
							rows.push({items, cols, index});
							items = [];
							index++;
						}
					});
				}
				else {
					// стандартные колонки LG:3, MD:4, SM:6, XS:6
					cols = 6;
					if (this.isLG) cols = 3;
					else if (this.isMD) cols = 4;
					else if (this.isSM) cols = 6;
					else if (this.isXS && this.xsCols) cols = Number(this.xsCols);
					//else cols = 6;

					const colsPerRow = 12 / cols;
					this.itemsProcessed.forEach((el, i) => {
						if (customCols && customCols[i]) {
							let c = null;
							if (this.isLG && customCols[i].lg) c = customCols[i].lg;
							else if (this.isMD && customCols[i].md) c = customCols[i].md;
							else if (this.isSM && customCols[i].sm) c = customCols[i].sm;
							else if (this.isXS && customCols[i].xs) c = customCols[i].xs;
							if (c) Vue.set(el, "cols", c);
						}
						items.push(el);

						if (items.length >= colsPerRow) {
							rows.push({items, cols, index});
							items = [];
							index++;
						}
					});
				}
				if (items.length) rows.push({items, cols, index});

				return rows;
			},
		},
		methods: {
			selectedTriLeft(row) {
				if (!this.selectedItem) return 0;

				const list = document.getElementsByClassName("flavour-list");
				const listRect = list[0].getBoundingClientRect();

				const el = document.getElementById("hover"+this.selectedItem.ID);
				const rect = el.getBoundingClientRect();

				return ((rect.right + rect.left) / 2) - listRect.left;

				const index = this.items.findIndex(el => el.ID === this.selectedItem.ID);
				if (index === -1) return 0;

				let v;

				if (this.isLG) {
					if ( Math.floor(this.items.length/4)*4 <= index) {
						const left = this.items.length % 4;

						if (left === 3) v = (((1 + index % 4) * 25));
						else if (left === 2) v = (((1 + index % 4) * 25) + 11.5);
						else v = 50;
					} else {
						v = ((index % 4 * 25) + 11.5);
					}
				} else if (this.isMD) {
					if ( Math.floor(this.items.length/3)*3 <= index) {
						const left = this.items.length % 3;
						if (left === 2) v = (index % 3) * 33.33 + 33.33;
						else v = 50;
					} else {
						v = (index % 3) * 33.33 + 16.66;
					}
				} else {
					if (row.cols === 12) {
						v = 50;
					} else {
						if ( Math.floor(this.items.length/2)*2 <= index) {
							v = 50;
						} else {
							v = (index % 2) * 50 + 24.5;
						}
					}
				}

				//console.log("left: " + v + "%");
				return v;
			},
			nameHtml(item) {
				//return item.name.substr(0, 1).toUpperCase() + item.name.substr(1).toLowerCase();
				let name = item.NAME;
				name = name.replace(/(^.+?)(в )?(горьком|молочном).+$/is, "$1");
				name = name.replace(/(^.+?)filled with .+$/is, "$1");
				name = name.replace(/(^.+?)\d+ g$/is, "$1");
				name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1");
				/*if ( name.match(/(^.+?)(\d+ pcs)$/is) ) {
					name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1<br>$2");
					if ( item.WEIGHT ) name += " / "+item.WEIGHT + " g";
				}
				else if ( item.WEIGHT ) name += "<br>"+item.WEIGHT + " g";*/

				return name;
			},
			subnameHtml(item) {
				if ( item.SUBNAME ) return item.SUBNAME;
				//return item.choco.substr(0, 1).toUpperCase() + item.choco.substr(1).toLowerCase();
				let choco = null;
				if (item.NAME.match(/.+?filled with .+$/)) {
					choco = item.NAME.replace(/.+?(filled with.+$)/is, "$1");
					choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
				} else if (item.NAME.match(/.+?(в )?(горьком|молочном).+$/)) {
					choco = item.NAME.replace(/.+?((в )?(горьком|молочном).+$)/is, "$1");
					choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
				} else if (item.NAME.match(/\d+ g$/)) {
					// todo make this related to item.WEIGHT
					choco = item.NAME.replace(/.+?(\d+ g$)/is, "$1");
				} else if (item.NAME.match(/\d+ pcs$/)) {
					choco = item.NAME.replace(/.+?(\d+ pcs$)/is, "$1");
					if (item.WEIGHT) choco += " / " + item.WEIGHT + " g";
				}

				return choco;
			},
			choco(item) {
				//return item.choco.substr(0, 1).toUpperCase() + item.choco.substr(1).toLowerCase();
				return item.NAME.replace(/.+?((in )?(dark|milk).+$)/is, "$1");
			},
			setOver(item, v) {
				Vue.set(item, "isOver", v);
			},
			imagesStyle(item) {
				if ( item.isProduct) {
					const customStyle = this.category.UF_COLS_JSON?.productImagesStyle;
					let c;
					if ( customStyle ) {
						if (this.isLG && customStyle.lg) c = customStyle.lg;
						else if (this.isMD && customStyle.md) c = customStyle.md;
						else if (this.isSM && customStyle.sm) c = customStyle.sm;
						else if (this.isXS && customStyle.xs) c = customStyle.xs;
						if ( c ) return c;
					}
				}
				return null;
			},
			itemStyle(item) {
				if ( item.PREVIEW_STYLE ) return item.PREVIEW_STYLE;
				else if ( item.isProduct) {
					const customStyle = this.category.UF_COLS_JSON?.productItemStyle;
					let c;
					if ( customStyle ) {
						if (this.isLG && customStyle.lg) c = customStyle.lg;
						else if (this.isMD && customStyle.md) c = customStyle.md;
						else if (this.isSM && customStyle.sm) c = customStyle.sm;
						else if (this.isXS && customStyle.xs) c = customStyle.xs;
						if ( c ) return c;
					}
					return {
						width: "auto",
						height: "auto",
					};
				}
				else if (this.itemWidth || this.itemHeight) return {
					width: this.itemWidth ? this.itemWidth + "px" : "auto",
					height: this.itemHeight ? this.itemHeight + "px" : "auto",
				};
			},
			itemStyleMini(item) {
				/*if ( this.itemHeight ) return {
					height: this.itemHeight + "px"
				};*/
				//let s = {};
				//return s;
			},
			onClick(item) {
				if ( item.isProduct) return;
				//this.selectedItem = null;
				this.isSelectedItemVisible = false;

				if (this.selectedItem?.ID === item.ID) this.selectedItem = null;
				else {
					const el = document.getElementById("hover"+item.ID);
					const closestRow = el.closest(".row");// "hover" + item.ID;
					//const id = closestCol.attr("id");//"hover" + item.ID;

					let offset = -140;
					if (this.isMD) offset = -150;
					else if (this.isSM) offset = -120;

					if (this.selectedItem) {
						//  info уже открыто

						const row = this.rows.find(r => r.items.some(c => (c.ID===item.ID||(c.mini&&c.mini.ID===item.ID))));
						const selectedRow = this.rows.find(r => r.items.some(c => (c.ID===this.selectedItem.ID||(c.mini&&c.mini.ID===this.selectedItem.ID))));
						if (row.index === selectedRow.index) this.selectedItem = item;
						else {
							this.selectedItem = null;
							setTimeout(() => {
								scrollTo(closestRow, offset);
								setTimeout(() => {
									this.selectedItem = item;
								}, 300);
							}, 300);
						}
					} else {
						scrollTo(closestRow, offset);
						setTimeout(() => {
							this.selectedItem = item;
						}, 300);
					}

				}

			},
            onBuy(item) {
                const productSection = this.productCategories.find(el => el.ID === this.category.UF_PRODUCT_SECTION_ID);
                if (!productSection) return;

                if (item.PRODUCT_ID) {

                    const product = this.products.find(el => el.ID === item.PRODUCT_ID);
                    if ( product ) {
                        console.log("product", product);
                        console.log("productSection", productSection);
                        this.$router.push({
                            name: "product",
                            params: {
                                sectionCode: productSection.CODE,
                                code: product.CODE
                            },
                            query: {
                                flavourId: item.ID
                            },
                        });
                        return;
                    }
                }
                this.$router.push({
                    name: "category",
                    params: {
                        code: productSection.CODE
                    },
                    query: {
                        flavourId: item.ID
                    },
                });
            },
			onClose(item) {
				this.selectedItem = null;
			}
		},
		mounted() {
			this.idCounter = idCounter++;

			//this.selectedItem = this.items[0];

			/*if (!this.isMD) {
				setTimeout(() => {
					const swiper = new Swiper('#flavour-list' + this.idCounter, {
						freeMode: true,
						slidesPerView: 1,
						spaceBetween: 0,
						//centeredSlides: true,
						breakpoints: {
							320: {
								slidesPerView: 1,
								//spaceBetween: 10
							},
							/!*360: {
						slidesPerView: 1.5,
						//spaceBetween: 10
					},*!/
							480: {
								slidesPerView: this.items?.length > 2 ? 2.0 : this.items?.length,
								//spaceBetween: 20
							},
							600: {
								slidesPerView: this.items?.length > 2 ? 2.5 : this.items?.length,
								//spaceBetween: 20
							},
							720: {
								slidesPerView: this.items?.length > 3 ? 3.0 : this.items?.length,
								//spaceBetween: 20
							},
							840: {
								slidesPerView: this.items?.length > 3 ? 3.5 : this.items?.length,
								//spaceBetween: 20
							},
							960: {
								slidesPerView: this.items?.length > 4 ? 4.0 : this.items?.length,
								//spaceBetween: 20
							},
							/!*1000: {
						slidesPerView: 6.5,
						//spaceBetween: 20
					}*!/
						}
					});
				}, 150);
			}*/
		}
	}
</script>

<style lang="scss">
	.flavour-list {

		&__item {
			//position: relative;

			&-images {
				display: flex;
				align-items: flex-end;
			}

			&-img {
				position: relative;
				width: 80px;
				height: 80px;

				@include up($sm) {
					width: 110px;
					height: 110px;
				}

				@include up($md) {
					width: 110px;
					height: 110px;
				}

				@include up($lg) {
					width: 132px;
					height: 132px;
				}
			}

			&-img-mini {
				position: relative;
				width: 50px;
				height: 50px;
				margin-left: 4px;

				@include up($sm) {
					width: 69px;
					height: 69px;
					margin-left: 6px;
				}

				@include up($md) {
					width: 69px;
					height: 69px;
					margin-left: 6px;
				}

				@include up($lg) {
					margin-left: 12px;
					width: 83px;
					height: 83px;
				}

				.cut {
					// todo this bugz in webkit
					//transform-origin: 50%;
					//transform: rotate(180deg) rotateY(180deg);
				}
			}

			&-text {
				@include transition();

				&.pointer {
					&:hover {
						opacity: 0.6;
					}
				}
			}

			&.no-transition {
				.v-image {
					&.image {
						transition: none !important;
					}
					&.cut {
						transition: none !important;
					}
				}
			}
		}

		.hover {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			width: 100%;
			height: 100%;
			//background: rgba(0,0,0,0.2);
			//cursor: pointer;

			&:hover ~ .cut {
				opacity: 1;
				transition: 0.2s opacity;
			}

			&:hover ~ .image {
				opacity: 0;
				transition: 0.5s opacity;
			}

			&:hover ~ .single {
				filter: brightness(1.02) contrast(1.02);
				transform: translateY(-2px);
			}
		}

		.under {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: -55px;
			width: 200px !important;
			max-width: 200px !important;
			height: 200px !important;

			@include up($sm) {
				top: -55px;
				width: 250px !important;
				max-width: 250px !important;
				height: 250px !important;
			}

			@include up($md) {
				top: -90px;
				width: 300px !important;
				max-width: 300px !important;
				height: 300px !important;
			}

			@include up($lg) {
				top: -100px;
				width: 360px !important;
				max-width: 360px !important;
				height: 360px !important;
			}
		}

		.v-image {
			height: 100%;

			&.single {
				transition: 0.2s transform, filter;
			}

			&.image {
				transition: 0.2s opacity;
			}

			&.cut {
				//@include transition();
				transition: 0.5s opacity;

				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				opacity: 0;
			}
		}

		&__name {
			color: $golden;
			//max-width: 200px;
			//white-space: nowrap;
			//max-width: 240px;
		}

		&__choco {
			color: $golden;
			//color: $default-text-color;
			//max-width: 200px;
			//text-transform: lowercase;

			@include up($md) {
				//padding-top: 1px;
				//font-weight: 300;
				//color: $golden;
				//text-transform: lowercase;
			}
		}

		&.swiper {
			overflow: visible;
		}
	}


</style>