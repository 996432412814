<template>
	<section class="section--padding text-center text-md-start laurent">
		<v-container class="container--narrow">
			<h2 class="mb-10 d-md-none">Лоран Морено,</br>бренд-кондитер</h2>
			<v-row justify="space-between">
				<v-col cols="12" md="6" class="pr-md-10 pr-md-14 d-flex justify-center justify-md-start">
					<animated-img :src="require('@/assets/laurent/laurent.jpg')" alt="Laurent Moreno"
								  :reversed="true"
								  @loaded="isLoaded=true"
								  class="mb-8"/>
				</v-col>
				<v-col cols="12" md="6" lg="5" class="pl-md-8 pl-lg-0 d-flex align-center justify-center justify-md-start">
					<div v-if="isLoaded" class="anim-fade-in">
						<h3 class="mb-8 d-none d-md-block">
							Лоран Морено,</br>бренд-кондитер
						</h3>
						<p class="limited">
							'Я ценю и предпочитаю простые вещи, созданные руками человека. Я ремесленник и я вкладываю всего себя в то, что я делаю... Мое счастье в эмоциях, которые испытывают люди, откусывая мою конфету или передавая ее из руки в руку любимому человеку...'
						</p>
						<svgicon name="signature2" original class="d-block mx-auto mx-md-0 mt-6 laurent__signature"/>
					</div>
				</v-col>
			</v-row>
		</v-container>

	</section>
</template>

<script>
    import AnimatedImg from "@/components/animated-img";

    export default {
        name: "laurent",
        components: {
            AnimatedImg
        },
		data: () => {
            return {
                isLoaded: false
			}
		}
    }
</script>

<style lang="scss">
	.laurent {
		background: $primary-50;
		&__signature {
			max-width: 300px;
			//fill: $golden;
		}
	}
</style>