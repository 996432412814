<template>
	<section class="section--padding text-center text-md-start history2">
		<v-container class="container--narrow">
			<v-row>
				<v-col cols="12" md="6" class="d-flex justify-center justify-md-start">
					<animated-img :src="require('@/assets/home/history/h2.jpg')" alt="Shop"
								  :reversed="false"
								  class="my-8 mt-md-4 history2__img"/>
				</v-col>
				<v-col cols="12" md="6" class="pr-md-10 order-md-first d-flex justify-center justify-md-start">
					<div class="pt-md-4">
						<p class="limited">
							Конфеты Счастья так полюбились гостям, что за ними
							приходили специально, особенно в преддверии праздников и,
							именно это послужило импульсом для создания Шоколадной
							Фабрики СЧАСТЬЕ и ее уютных магазинов в Петербурге,
							Москве и Париже.
						</p>
						<p class="limited">
							Три первых вкуса пралине с течением времени
							сохранили свои цвета фантиков и рецептуру практически
							в первозданном виде и легли в основу базовой коллекции
							из 14 вкусов оригинальных пралине, которые по-прежнему
							дарят радость самым близким.
						</p>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script>
    import AnimatedImg from "@/components/animated-img";

    export default {
        name: "history2",
        components: {
            AnimatedImg
        },
    }
</script>

<style lang="scss">
	/*.history2 {
		background: $primary-50;
	}*/
</style>