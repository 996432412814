<template>
	<div class="category-list relative">
		<v-container class="text-center mb-8 mb-md-12">
			<h3>НАШИ КОЛЛЕКЦИИ</h3>
			<div class="manual color-black">
				<v-img height="64px" contain src="~@/assets/category/choose-favorite.svg"/>
			</div>
		</v-container>
		<!--    <div class="swiper category-list__list" @mousemove="onMouseMove" @mouseout="onMouseOut">-->
		<div class="swiper category-list__list">
			<div class="swiper-wrapper">
				<div class="swiper-slide text-center" v-for="(item, index) in items" :key="index">
					<router-link :to="{name:'category', params:{code:item.CODE}}"
								 class="pa-4 d-flex flex-column align-center category-list__image-link">
						<v-img :lazy-src="item.PICTURE" :src="item.PICTURE" contain :alt="item.NAME"/>
					</router-link>
					<router-link :to="{name:'category', params:{code:item.CODE}}"
								 class="mt-6 mt-md-8 category-list__link">
						<h5>{{ item.NAME }}</h5>
						<!--<p class="size2 mt-2 mt-md-3" v-html="item.DESCRIPTION"></p>-->
					</router-link>

					<!--<btn-primary label="Shop now" @click="$router.push({name:'category', params:{code:item.CODE}})"/>-->
				</div>
			</div>
		</div>

		<div class="category-list__btns">
			<button class="category-list__btn-next">
				<svgicon name="arrow-right2"/>
			</button>
			<button class="category-list__btn-prev">
				<svgicon name="arrow-right2"/>
			</button>
		</div>

		<div class="category-list__mouse-move" @mousemove="onMouseMove" @mouseout="onMouseOut">
			<div class="category-list__mouse-move-item"></div>
			<div class="category-list__mouse-move-item --2"></div>
		</div>

	</div>
</template>

<script>
	import {Swiper, Navigation} from 'swiper';
	import 'swiper/swiper.min.css';
	import vp from "@/mixins/vp";
	import BtnPrimary from "./btn-primary";
	import {debounce, throttle} from "@/utils/utils";

	let swiper;

	export default {
		name: "category-list",
		components: {BtnPrimary},
		mixins: [vp],
		props: ["items"],
		data() {
			return {
				speed: 0,
				interval: null
			}
		},
		computed: {
			page() {
				if (this.$store.state.page.CatalogController) return this.$store.state.page.CatalogController;
				return this.$store.state.page.HomeController;
			},
		},
		methods: {
			/*onMouseOverSlide(slide, slideIndex) {
				if ( !swiper ) return;
				swiper.slideTo(slideIndex);
			},*/
			moveSlider() {
				if (!swiper) return;
				swiper.setProgress(swiper.progress + this.speed);
			},
			onMouseOut(event) {
				clearInterval(this.interval);
				this.interval = null;
			},
			onMouseMove(event) {
				if (!this.isMD) {
					clearInterval(this.interval);
					this.interval = null;
					return;
				}
				if (!swiper) return;
				const width = swiper.el.offsetWidth;
				const clientX = event.clientX;
				console.log("WIDTH: ", width);
				console.log("clientX: ", clientX);
				console.log("swiper.isBeginning: ", swiper.isBeginning);
				console.log("swiper.isEnd: ", swiper.isEnd);
				const WING = .2;
				const SPEED = 16;
				let speed = 0;
				if (clientX >= width * (1 - WING) && !swiper.isEnd) {
					speed = SPEED * (clientX - width * (1 - WING)) / (width * WING);
					//console.log("NEXT", speed);
					// this.speed = .00001 * speed;
					this.speed = .0001 * speed;
				} else if (clientX <= width * WING && !swiper.isBeginning) {
					speed = SPEED * (width * WING - clientX) / (width * WING);
					//console.log("PREV", speed);
					this.speed = -.0001 * speed;
				} else {
					clearInterval(this.interval);
					this.interval = null;
					return;
				}

				if (!this.interval) this.interval = setInterval(this.moveSlider, 1);
			}
		},
		mounted() {
			swiper = new Swiper('.swiper.category-list__list', {
				freeMode: true,
				modules: [Navigation],
				slidesPerView: 1.5,
				spaceBetween: 0,
				navigation: {
					nextEl: '.category-list__btn-next',
					prevEl: '.category-list__btn-prev',
				},
				//mousewheel: true,
				//grabCursor: true,
				breakpoints: {
					360: {
						slidesPerView: 1.5,
						//spaceBetween: 5
					},
					400: {
						slidesPerView: 1.5,
						//spaceBetween: 5
					},
					520: {
						slidesPerView: 2,
						//spaceBetween: 20
					},
					680: {
						slidesPerView: 2.5,
						//spaceBetween: 20
					},
					840: {
						slidesPerView: 3,
						//spaceBetween: 20
					},
					/*1000: {
						slidesPerView: 3.5,
						//spaceBetween: 20
					},*/
					1160: {
						slidesPerView: 3,
						//slidesPerGroup: 3,
						//spaceBetween: 20
					},
					1320: {
						slidesPerView: 3.4,
						//slidesPerGroup: 4,
						//spaceBetween: 20
					},
					/*1480: {
						slidesPerView: 4,
						//spaceBetween: 20
					},*/
					1640: {
						freeMode: true,
						slidesPerView: 4,
						//slidesPerGroup: 4,
						//spaceBetween: 20
					}/*,
					1800: {
						slidesPerView: 5.5,
						//spaceBetween: 20
					}*/
				}
			});
			//swiper.mousewheel.enable();
		}
	}
</script>

<style lang="scss">
	.category-list {

		&__list {
			//min-width: 1480px;	// todo for more categories
			scroll-behavior: smooth;

			@include up($md) {
				padding: 0 80px;
			}

			.swiper-slide {
				position: relative;
				height: 400px;

				@include up($md) {
					height: 450px;
					//min-width: 400px;
				}
			}
		}

		&__image-link {
			// todo bolder bottom line

			@include up($md) {
				&:hover {
					opacity: unset;
					//transform: translateY(1px);
					filter: brightness(1.1) contrast(1.05);
				}
			}
		}

		&__link {
			display: inline-block;
			padding: 0 3px 2px 3px;
			border-bottom: 1px solid transparent;

			&:hover, &:active, &:focus {
				//opacity: 0.6;
				//text-decoration-color: $primary-500;
				border-bottom: 1px solid $black-50;
			}
		}

		.v-image {
			//display: block;
			//margin: 0;
			//width: 285px;
			height: 220px;

			@include up($md) {
				min-width: 370px;
			}

			@include up($lg) {
				//width: 330px;
				height: 280px;
			}

			/*@include up($lg) {
				width: 330px;
				height: 249px;
			}*/
		}

		h5 {
			color: $golden;
			max-width: 215px;
		}

		p {
			color: $default-text-color;
			max-width: 260px;
		}

		.category-list__btns {
			button {
				display: block;
				width: 48px;
				//height: 30px;
				align-items: center;
				position: absolute;
				z-index: 4;
				padding: 20px 5px;

				svg {
					width: 100%;
					@include transition();
					fill: $golden;
				}

				&:hover {
					svg {
						opacity: 0.6;
					}
				}

				&.category-list__btn-next {
					display: none;
					//top: -5px;
					top: 50%;
					right: 0;
					transform: translateY(-50%);

					@include up($md) {
						display: inline-block;
						right: 20px;
					}
				}

				&.category-list__btn-prev {
					display: none;
					top: 50%;
					left: 0;
					transform: rotate(180deg) translateY(50%);

					@include up($md) {
						display: inline-block;
						left: 20px;
					}
				}
			}

			.swiper-button-disabled {
				svg {
					fill: rgba(25, 25, 26, 0.16);
				}
			}
		}

		&__mouse-move {
			width: 100%;
			height: 100%;

			&-item {
				position: absolute;
				left: 0;
				top: 50%;
				width: 10%;
				height: 450px;
				background: transparent;
				transform: translateY(-50%);
				z-index: 3;

				&.--2 {
					right: 0;
					left: auto;
				}
			}
		}
	}
</style>