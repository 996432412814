<template>
	<div v-if="items && items.length" class="home-swiper">
		<div class="swiper home-swiper__swiper" :id="'homeSwiper'+this.idCounter">
			<div class="swiper-wrapper">
				<div v-for="(item, index) in items" :key="index"
					 class="swiper-slide">
					<div class="d-flex align-center justify-center pa-4">
						<animated-img v-if="activeIndex===index" :src="item.img"
									  :reversed="item.reversed"
									  :alt="alt"/>
					</div>
				</div>
			</div>
		</div>
		<div class="home-swiper__btns">
			<button class="home-swiper__btn-next" :data-swiper-id="idCounter"
					:class="{disabled:activeIndex===items.length-1}">
				<svgicon name="arrow-right2"/>
			</button>
			<button class="home-swiper__btn-prev" :data-swiper-id="idCounter"
					:class="{disabled:activeIndex===0}">
				<svgicon name="arrow-right2"/>
			</button>
		</div>
	</div>
</template>

<script>
    import Swiper, {Navigation, Pagination, EffectFade} from 'swiper';
    //import {Navigation} from 'swiper/navigation';
    import 'swiper/swiper.min.css';
    import AnimatedImg from "@/components/animated-img";

    let idCounter = 1;

    export default {
        name: "home-swiper",
        props: ["items", "alt"],
        components: {
            AnimatedImg,
        },
        data() {
            return {
                activeIndex: 0,
                idCounter: 0
            }
        },
        mounted() {
            this.idCounter = idCounter++;

            setTimeout(()=>{
                const swiper = new Swiper('#homeSwiper'+this.idCounter, {
                    //freeMode: true,
                    slidesPerView: 1,
                    //spaceBetween: 0,
                    //allowTouchMove: false,
                    effect: "fade",
                    modules: [Navigation, EffectFade],
                    navigation: {
                        nextEl: ".home-swiper__btn-next[data-swiper-id='"+this.idCounter+"']",
                        prevEl: ".home-swiper__btn-prev[data-swiper-id='"+this.idCounter+"']",
                    },
                    on: {
                        slideChange: (event) => {
                            this.activeIndex = event.activeIndex;
                        },
                    },
                });
			},150);
        }
    }
</script>

<style lang="scss">
	.home-swiper {
		position: relative;

		@include up($sm) {
			max-width: 340px;
			margin: 0 auto;
		}

		@include up($md) {
			max-width: unset;
			margin: unset;
		}

		&__btns {
			button {
				display: block;
				width: 32px;
				//height: 30px;
				align-items: center;
				position: absolute;

				@include up($lg) {
					//width: 40px;
				}

				svg {
					width: 100%;
					@include transition();
					fill: $golden;
				}

				&:hover {
					svg {
						opacity: 0.6;
					}
				}

				&.home-swiper__btn-next {
					display: none;
					//top: -5px;
					top: 50%;
					right: 0;
					transform: translateY(-50%);

					@include up($sm) {
						display: inline-block;
						right: -30px;
					}

					@include up($lg) {
						right: -50px;
					}
				}

				&.home-swiper__btn-prev {
					display: none;
					top: 50%;
					left: 0;
					transform: rotate(180deg) translateY(50%);

					@include up($sm) {
						display: inline-block;
						left: -30px;
					}

					@include up($lg) {
						left: -50px;
					}
				}

				&.disabled {
					svg {
						fill: rgba(25, 25, 26, 0.16);
					}
				}
			}
		}
	}
</style>